import React from "react"
import Biodata from "../components/Biodata/Biodata"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

const AboutPage = () => (
  <Layout>
    <Seo title="About" />
    <Biodata />
  </Layout>
)

export default AboutPage
