import React from "react"
import Image from "../Images/Images"
import Title from "../Headings/Title"
import {
  FaFacebookF,
  FaGithubAlt,
  FaGlobe,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa"

const Biodata = () => (
  <section className="biodata-section">
    <Title>About...</Title>
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div
            className="card shadow mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div className="card-body">
              <div className="d-flex flex-column align-items-center text-center">
                <Image
                  className="shadow rounded-circle mb-2"
                  filename="Abdullah.jpg"
                  style={{
                    width: "160px",
                    height: "160px",
                    margin: "0 auto",
                  }}
                  alt="Abdullah"
                />
                <div className="mt-3">
                  <h4>John Doe</h4>
                  <p className="text-secondary mb-1">Full Stack Developer</p>
                  <p className="text-muted font-size-sm">
                    Bay Area, San Francisco, CA
                  </p>
                  <button className="btn btn-outline-primary shadow m-2">
                    Follow
                  </button>
                  <button className="btn btn-outline-primary shadow m-2">
                    Message
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card shadow mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div className="card-body">
              <li className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0">
                  <FaGlobe /> Website
                </h6>
                <span className="text-secondary">https://bootdey.com</span>
              </li>
              <hr />
              <li className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0">
                  <FaGithubAlt /> Github
                </h6>
                <span className="text-secondary">bootdey</span>
              </li>
              <hr />
              <li className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0">
                  <FaTwitter /> Twitter
                </h6>
                <span className="text-secondary">@bootdey</span>
              </li>
              <hr />
              <li className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0">
                  <FaInstagram /> Instagram
                </h6>
                <span className="text-secondary">bootdey</span>
              </li>
              <hr />
              <li className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0">
                  <FaFacebookF /> Facebook
                </h6>
                <span className="text-secondary">bootdey</span>
              </li>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div
            className="card shadow mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3">
                  <h6 className="mb-0">Full Name</h6>
                </div>
                <div className="col-sm-9 text-secondary">Kenneth Valdez</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <h6 className="mb-0">Email</h6>
                </div>
                <div className="col-sm-9 text-secondary">fip@jukmuh.al</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <h6 className="mb-0">Phone</h6>
                </div>
                <div className="col-sm-9 text-secondary">(239) 816-9029</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <h6 className="mb-0">Mobile</h6>
                </div>
                <div className="col-sm-9 text-secondary">(320) 380-4539</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <h6 className="mb-0">Address</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                  Bay Area, San Francisco, CA
                </div>
              </div>
            </div>
          </div>
          <div className="row gutters-sm">
            <div className="col-sm-6 mb-3">
              <div
                className="card shadow mb-4 h-100 border-primary"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <div className="card-body">
                  <h6 className="d-flex align-items-center mb-3">
                    <i className="material-icons text-info me-2">assignment</i>
                    Project Status
                  </h6>
                  <p className="mb-1">Web Design</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "66%" }}
                      aria-valuenow="80"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="mb-1">Website Markup</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "66%" }}
                      aria-valuenow="72"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="mb-1">One Page</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "66%" }}
                      aria-valuenow="89"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="mb-1">Mobile Template</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "66%" }}
                      aria-valuenow="55"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="mb-1">Backend API</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "66%" }}
                      aria-valuenow="66"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-3">
              <div
                className="card shadow mb-4 h-100 border-primary"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <div className="card-body">
                  <h6 className="d-flex align-items-center mb-3">
                    <i className="material-icons text-info me-2">assignment</i>
                    Project Status
                  </h6>
                  <p className="mb-1">Web Design</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "66%" }}
                      aria-valuenow="80"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="mb-1">Website Markup</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "66%" }}
                      aria-valuenow="72"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="mb-1">One Page</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "66%" }}
                      aria-valuenow="89"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="mb-1">Mobile Template</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "56%" }}
                      aria-valuenow="55"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="mb-1">Backend API</p>
                  <div
                    className="progress mb-3"
                    style={{ height: "10px", borderRadius: "10px" }}
                  >
                    <div
                      className="progress-bar bg-pimary"
                      role="progressbar"
                      aria-label="progressbar"
                      style={{ width: "66%" }}
                      aria-valuenow="66"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Biodata
